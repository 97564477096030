<template>
    <vs-table
        ref="table"
        v-model="selected"
        search
        :multiple="!singleSelect"
        :data="invoices"
        :sst="true"
        @sort="handleSort"
        @search="search"
        @selected="
            singleSelect
                ? $router.push({ name: 'invoice', params: { pk: $event.id } })
                : null
        "
    >
        <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-end"
        >
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>
            <!-- Actions -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2">Action</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="singleSelect = !singleSelect">
                        <span>{{
                            singleSelect ? 'Multiple Select' : 'Single Select'
                        }}</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item divider @click="$emit('createInvoice')">
                        <span>Create</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('createInvoices')">
                        <span>Create Multiple Broker</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        divider
                        :disabled="singleSelect"
                        @click="$emit('approvedSelected')"
                    >
                        <span>Approved</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        :disabled="singleSelect"
                        @click="$emit('sentSelected')"
                    >
                        <span>Sent</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        :disabled="singleSelect"
                        @click="$emit('paidSelected')"
                    >
                        <span>Paid</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        :disabled="singleSelect"
                        @click="$emit('deleteSelected')"
                    >
                        <span>Delete</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
            <!-- ITEMS PER PAGE -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2">{{ itemsPerPage }}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
                        <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
                        <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
                        <span>50</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
            <!-- Filter -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2">Filter - {{ limitLabel }}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('limit', 'all')">
                        <span>All</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('limit', 'approved')">
                        <span>Approved</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('limit', 'sent')">
                        <span>Sent</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('limit', 'paid')">
                        <span>Paid</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('limit', 'not_approved')">
                        <span>Not Approved</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('limit', 'not_sent')">
                        <span>Not Sent</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('limit', 'not_paid')">
                        <span>Not Paid</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <template slot="thead">
            <vs-th sort-key="broker">To</vs-th>
            <vs-th sort-key="description">Description</vs-th>
            <vs-th sort-key="from_date">Dates</vs-th>
            <vs-th sort-key="amount">Amount</vs-th>
            <vs-th sort-key="approved">Approved</vs-th>
            <vs-th sort-key="sent">Sent</vs-th>
            <vs-th sort-key="paid">Paid</vs-th>
            <vs-th sort-key="direct_debit_status">Broker DD</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr
                    :data="tr"
                    :key="index"
                    v-for="(tr, index) in data"
                    :state="!tr.paid ? 'danger' : null"
                >
                    <vs-td>{{ to(data[index]) }}</vs-td>

                    <vs-td>{{ data[index].description }}</vs-td>

                    <vs-td
                        >{{ formatDate(data[index].from_date) }} -
                        {{ formatDate(data[index].to_date) }}</vs-td
                    >

                    <vs-td>£{{ data[index].amount }}</vs-td>

                    <vs-td>
                        <feather-icon
                            :icon="data[index].approved ? 'CheckIcon' : 'XIcon'"
                            svg-classes="text-primary"
                        />
                    </vs-td>

                    <vs-td>
                        <feather-icon
                            :icon="data[index].sent ? 'CheckIcon' : 'XIcon'"
                            svg-classes="text-primary"
                        />
                    </vs-td>

                    <vs-td>
                        <feather-icon
                            :icon="data[index].paid ? 'CheckIcon' : 'XIcon'"
                            svg-classes="text-primary"
                        />
                    </vs-td>
                    <vs-td>
                        <vs-chip
                            v-if="
                                data[index].broker &&
                                data[index].broker.direct_debit_status
                            "
                            color="success"
                        />
                        <vs-chip
                            v-if="
                                data[index].broker &&
                                !data[index].broker.direct_debit_status
                            "
                            color="danger"
                        />
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'InvoicesAllTable',
    mixins: [textFormat, tableHelpers],
    props: ['data', 'itemsPerPage', 'dataSelected', 'limit'],
    computed: {
        invoices() {
            if (this.data) return this.data.data
            return null
        },
        selected: {
            set(value) {
                this.$emit('selected', value)
            },
            get() {
                return this.dataSelected
            },
        },
        limitLabel() {
            if (this.limit === 'all') return 'All'
            if (this.limit === 'paid') return 'Paid'
            if (this.limit === 'approved') return 'Approved'
            if (this.limit === 'sent') return 'Sent'
            if (this.limit === 'not_paid') return 'Not Paid'
            if (this.limit === 'not_approved') return 'Not Approved'
            if (this.limit === 'not_sent') return 'Not Sent'
            return ''
        },
    },
    data() {
        return {
            searchQuery: '',
            status: 'All',
            singleSelect: true,
        }
    },
    methods: {
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        setLimit(status, value) {
            this.status = status
            this.$emit('limit', value)
        },
        to(data) {
            if (data.broker) return data.broker.company_name
            if (data.charity) return data.charity.charity_name
            return ''
        },
        handleSort(key, value) {
            this.$emit('sort', [key, value])
        },
    },
}
</script>
